import React, { useState, useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Panel, PanelType, PrimaryButton } from "office-ui-fabric-react";
import { toast } from "react-toastify";
import Button from "./Button";
import Form from "../../../Common/Form";
import {
    postEmployee,
    getUserEmployeesLookup,
} from "../../../Services/EmployeeService";
import { useStateValue } from "../../../State/stateProvider";

// eslint-disable-next-line no-useless-escape
const emailTester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

// eslint-disable-next-line no-useless-escape
const phoneTester = /^[\+]?[(]?[0-9]{2,3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}[-\s\.]?([0-9]{3,6})?/;

const bonAmpetitCardTester = /^([\d]{16})?$/;

const capitalizeFirstLetterOfEveryWord = (inputString) => {
    if (!inputString) return inputString;

    const pattern = /(\p{L})(\p{L}*)/gu;
    const transform = (_, firstLetter, restOfWord) =>
        firstLetter.toUpperCase() + restOfWord.toLowerCase();

    return inputString.replace(pattern, transform);
};

const AddEmployee = () => {
    const location = useLocation();

    const [{management, allLabels: {labels}}, dispatch] = useStateValue();
    const {jobTitles, employeeTypes, unitsDictionary} = management;
    const [userLookupEmployees, setUserLookupEmployees] = useState([
        {key: "", text: ""},
    ]);

    const matchRestaurant = matchPath(location.pathname, {
        path: "/restaurant/:unit",
        exact: true,
        strict: false,
    });

    const unitId = matchRestaurant?.params.unit;

    const [item, setItem] = useState({
        unitId,
    });

    useEffect(() => {
        setItem((oldItem) => ({...oldItem, unitId}));
    }, [unitId]);

    const openAddPanel = () => setIsCreating(true);
    const dismissAddPanel = () => setIsCreating(false);

    const [isCreating, setIsCreating] = useState(false);

    useEffect(() => {
        let mounted = true;
        const updateEmployees = async () => {
            if (isCreating && item.unitId) {
                const userEmployees = await getUserEmployeesLookup();
                if (mounted) {
                    if (userEmployees.data) {
                        setUserLookupEmployees([
                            {key: "", text: ""},
                            ...userEmployees.data.map((entry) => ({
                                ...entry,
                                key: entry.id,
                                text: `${ entry.firstname } ${ entry.surname }`,
                            })),
                        ]);
                    } else {
                        setUserLookupEmployees([{key: "", text: ""}]);
                    }
                }
            }
        };
        updateEmployees();

        return () => {
            mounted = false;
        };
    }, [isCreating, item.unitId]);

    const confirmChanges = async () => {
        const error = Object.entries(item).find(([key, value]) => {
            if (key === "emailPrivate" || key === "emailWork") {
                if (value && !emailTester.test(value)) {
                    return true;
                }
            }

            if (key === "phoneMobile" || key === "phonePrivate") {
                if (value && !phoneTester.test(value)) {
                    return true;
                }
            }

            if (key === "bonAmpetitCard") {
                if (value && !bonAmpetitCardTester.test(value)) {
                    return true;
                }
            }

            return false;
        });

        const requiredDataMissing =
            !item.firstname ||
            !item.surname ||
            !item.emailPrivate ||
            !(item.level >= 0) ||
            !item.jobTitleId ||
            !item.employeeTypeId;

        item.firstname = capitalizeFirstLetterOfEveryWord(item.firstname);
        item.surname = capitalizeFirstLetterOfEveryWord(item.surname);

        if (!error && !requiredDataMissing) {
            const response = await postEmployee(item);

            if (response) {
                toast.success(labels.get('employee_added'));
                setItem({
                    unitId,
                });
                dispatch({
                    type: "changeRefreshData",
                });
                dismissAddPanel();
            }
        } else {
            if (error) {
                toast.error(labels.get('correct_form_errors'));
            }
            if (requiredDataMissing) {
                toast.error(labels.get('fill_required_fields'));
            }
        }
    };

    const changeItem = (value, field) => {
        const newItem = {...item, [field]: value};

        if (field === "unitId") {
            if (value?.id) {
                if (newItem.unitId !== value.id) {
                    newItem.supervisorId = null;
                    newItem.supervisor = null;
                }

                newItem.unitId = value.id;
                newItem.unit = value;
            } else {
                newItem.unitId = null;
                newItem.unit = null;
                newItem.supervisorId = null;
                newItem.supervisor = null;
            }
        } else if (field === "employeeTypeId") {
            if (value?.id) {
                newItem.employeeTypeId = value.id;
                newItem.employeeType = value;
            } else {
                newItem.employeeTypeId = null;
                newItem.employeeType = null;
            }
        } else if (field === "jobTitleId") {
            if (value?.id) {
                newItem.jobTitleId = value.id;
                newItem.jobTitle = value;
                newItem.level = jobTitles.find(j => j.id === value.id)?.defaultLevel;
            } else {
                newItem.jobTitleId = null;
                newItem.jobTitle = null;
                newItem.level = null;
            }
        } else if (field === "supervisorId") {
            if (value?.id) {
                newItem.supervisorId = value.id;
                newItem.supervisor = value;
            } else {
                newItem.supervisorId = null;
                newItem.supervisor = null;
            }
        }

        setItem(newItem);
    };

    const onRenderFooterContent = () => (
        <div>
            <PrimaryButton onClick={ confirmChanges }>{ labels.get('save') }</PrimaryButton>
        </div>
    );

    const fields = [
        {
            type: "column",
            key: "1",
            separator: true,
            fields: [
                {
                    type: "dropdown",
                    label: labels.get('restaurant'),
                    name: "unitId",
                    options: unitsDictionary,
                },
            ],
        },
        {
            type: "column",
            key: "2",
            fields: [
                {
                    type: "dropdown",
                    label: labels.get('supervisor'),
                    name: "supervisorId",
                    options: userLookupEmployees,
                },
                {
                    type: "text",
                    label: labels.get('firstname'),
                    name: "firstname",
                    required: true,
                },
                {
                    type: "text",
                    label: labels.get('surname'),
                    name: "surname",
                    required: true,
                },
                {
                    type: "dropdown",
                    label: labels.get('job_title'),
                    name: "jobTitleId",
                    options: jobTitles,
                    required: true,
                },
            ],
        },
        {
            type: "columns",
            key: "3",
            fields: [
                {
                    type: "number",
                    label: labels.get('level'),
                    name: "level",
                    required: true,
                },
                {
                    type: "dropdown",
                    label: labels.get('employee_type'),
                    name: "employeeTypeId",
                    options: employeeTypes,
                    required: true,
                },
            ],
        },
        {
            type: "column",
            key: "4",
            separator: true,
            fields: [
                {
                    type: "text",
                    label: labels.get('bon_ampetit_card'),
                    name: "bonAmpetitCard",
                    required: false,
                },
            ],
        },
        {
            type: "columns",
            key: "5",
            fields: [
                {
                    type: "phone",
                    label: labels.get('phone_mobile'),
                    name: "phoneMobile",
                },
                {
                    type: "phone",
                    label: labels.get('phone_private'),
                    name: "phonePrivate",
                },
            ],
        },
        {
            type: "columns",
            key: "6",
            fields: [
                {
                    type: "email",
                    label: labels.get('email_work'),
                    name: "emailWork",
                },
                {
                    type: "email",
                    label: labels.get('email_private'),
                    name: "emailPrivate",
                    required: true,
                },
                {
                    type: "date",
                    label: labels.get('start_work_day'),
                    name: "startWorkDate",
                },
                {
                    type: "date",
                    label: labels.get('end_work_day'),
                    name: "endWorkDate",
                },
            ],
        },
    ];

    return (
        <div>
            <Button
                name={ labels.get('add_new_employee') }
                icon="Add"
                className="addButton"
                action={ openAddPanel }
            />
            <Panel
                headerText={ labels.get('add_new_employee') }
                type={ PanelType.custom }
                customWidth={ "28rem" }
                isLightDismiss
                isOpen={ isCreating }
                onDismiss={ dismissAddPanel }
                closeButtonAriaLabel={ labels.get('close') }
                onRenderFooterContent={ onRenderFooterContent }
                isFooterAtBottom={ true }
            >
                <Form item={ item } changeItem={ changeItem } fields={ fields }/>
            </Panel>
        </div>
    );
};

export default AddEmployee;
