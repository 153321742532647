import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Nav } from 'office-ui-fabric-react';
import { useStateValue } from "../../../State/stateProvider";

const SettingsList = () => {
  const [{ allLabels: { labels } }] = useStateValue();

  const history = useHistory();
  const location = useLocation();
  const id = location.pathname;
  const settingsGroup = [{
    links: [
      {
        key: '/settings/job-titles',
        name: labels.get('job_titles'),
        url: '/settings/job-titles',
      },
      {
        key: '/settings/countries',
        name: labels.get('countries'),
        url: '/settings/countries',
      },
      {
        key: '/settings/cost-centers',
        name: labels.get('cost_centers'),
        url: '/settings/cost-centers',
      },
      {
        key: '/settings/companies',
        name: labels.get('companies'),
        url: '/settings/companies',
      },
      {
        key: '/settings/brands',
        name: labels.get('brands'),
        url: '/settings/brands',
      },
      {
        key: '/settings/restaurant-concepts',
        name: labels.get('restaurant_concepts'),
        url: '/settings/restaurant-concepts',
      },
      {
        key: '/settings/restaurant-asset-types',
        name: labels.get('restaurant_asset_types'),
        url: '/settings/restaurant-asset-types',
      },
      {
        key: '/settings/franchise-business-coaches',
        name: labels.get('franchise_business_coaches'),
        url: '/settings/franchise-business-coaches',
      },
      {
        key: '/settings/translations',
        name: labels.get('translations'),
        url: '/settings/translations',
      },
    ]
  }];

  const handleRedirect = (event, element) => {
    event.preventDefault();
    history.replace(element.url);
  };

  return (
    <div className='settings-list'>
      <div className='settings-list__header'>
        <h2>{labels.get('settings')}</h2>
      </div>
      <Nav
        groups={settingsGroup}
        onLinkClick={handleRedirect}
        selectedKey={id}
      />
    </div>
  );
};

export default SettingsList;
