import React, { useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Nav,
  FontIcon,
  ContextualMenu,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  Spinner,
  Dropdown,
} from "office-ui-fabric-react";
import { useStateValue } from "../../../State/stateProvider";
import Search from "./Search";
import EditStructure from "./EditStructure";
import EditRegion from "./EditRegion";
import RestaurantForm from "./RestaurantForm";
import Autocomplete from '../../../Common/Autocomplete';

const RestaurantList = () => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  const [{ auth, management, allLabels: { labels } }, dispatch] = useStateValue();
  const { groups } = auth;
  const {
    structuresWithUnits,
    regionsWithUnits,
    regionsDictionary,
    units,
    structures,
    unitsDictionary,
    loadingUnits,
  } = management;

  const editRegionRef = useRef(null);
  const editStructueRef = useRef(null);
  const restaurantFormRef = useRef(null);

  const openAddPanel = () => {
    if (restaurantFormRef?.current) {
      restaurantFormRef.current.openPanel("addRequest");
    }
  };

  const openEditPanel = () => {
    let unit;
    if (pathname.includes("restaurant")) {
      const unitId = pathname.split("/").pop();
      if (unitId) {
        unit = units.find((element) => element.id === unitId);
      }
    }

    if (restaurantFormRef?.current) {
      if (unit) {
        restaurantFormRef.current.openPanel("editRequest", {
          ...unit,
          unitId: unit.id,
          regionId: unit.region?.id,
          structureId: unit.structure?.id,
        });
      } else {
        restaurantFormRef.current.openPanel("editRequest");
      }
    }
  };

  const linkRef = useRef(null);
  const [showContextualMenu, setShowContextualMenu] = useState(false);
  const onShowContextualMenu = () => setShowContextualMenu(true);
  const onHideContextualMenu = () => setShowContextualMenu(false);

  const [dialog, setDialog] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [expanded, setExpanded] = useState(new Set());

  const [searchFilter, setSearchFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("Open");

  const openDialog = () => {
    setDialog(false);
  };

  const closeDialog = () => {
    setDialog(true);
    setSelectedItems([]);
  };

  const onDropdownChange = (_, items) => {
    setSelectedItems(items);
  };

  const handleExpand = (event, element) => {
    const newExpanded = new Set(expanded);
    if (expanded.has(element.id)) {
      newExpanded.delete(element.id);
    } else {
      newExpanded.add(element.id);
    }
    setExpanded(newExpanded);
  };

  const checkUnitPermission = (id) => {
    const groupIncludes =
    groups.includes("Global") ||
    groups.includes("Regional") ||
    groups.includes("Franchisee");
    const unitIncludes = !!units.find((unit) => unit.id === id);

    return groupIncludes && unitIncludes;
  };

  const generateLinks = (regions) => {
    return regions
      .map((region) => {
        let links = [];

        if (region.subRegions) {
          links = generateLinks(region.subRegions);
        }

        const units = region.units
          .filter((unit) => {
            if (statusFilter === "Open") {
              return !unit.isClosed;
            }
            if (statusFilter === "Closed") {
              return unit.isClosed;
            }
            return true;
          })
          .map((unit) => ({
            key: `/restaurant/${unit.id}`,
            id: unit.id,
            name: unit.unitName,
            url: `/restaurant/${unit.id}`,
            type: "restaurant",
            edit: checkUnitPermission(unit.id),
          }))
          .filter((unit) =>
            unit.name?.toLowerCase().includes(searchFilter?.toLowerCase())
          );

        if (
          searchFilter &&
          (!units || units.length === 0) &&
          (!links || links.length === 0) &&
          !region.name?.toLowerCase().includes(searchFilter?.toLowerCase())
        ) {
          return null;
        }

        const newRegion = {
          key: `/region/${region.id}`,
          id: region.id,
          name: region.name,
          depthLevel: region.depthLevel,
          url: `/region/${region.id}`,
          type: "region",
          edit: groups.includes("Global"),
          isExpanded: expanded.has(region.id) || searchFilter,
          links: [
            ...links?.sort((a, b) => a.name.localeCompare(b.name)),
            ...units?.sort((a, b) => a.name.localeCompare(b.name)),
          ],
        };

        return newRegion;
      })
      .filter((region) => !!region);
  };

  const mappedStructures = structuresWithUnits.map((structure) => {
    let links = generateLinks(
      regionsWithUnits.filter((region) => region.structureId === structure.id)
    );
    if (links?.some((link) => link.depthLevel === 0)) {
      const newLinks = [];
      links.forEach((link) => {
        if (link.depthLevel === 0) {
          newLinks.push(...link.links);
        } else {
          newLinks.push(link);
        }
      });
      links = newLinks;
    }

    const structureObject = {
      key: `/structure/${structure.id}`,
      code: structure.code,
      name: structure.name,
      id: structure.id,
      url: `/structure/${structure.id}`,
      expandAriaLabel: `${labels.get('expand')} ${structure.name} ${labels.get('restaurant_list')}`,
      collapseAriaLabel: `${labels.get('expand')} ${structure.name} ${labels.get('restaurant_list')}`,
      type: "structure",
      edit: groups.includes("Global"),
      isExpanded: expanded.has(structure.id) || searchFilter,
      links,
    };

    return structureObject;
  });

  const restaurantGroups = [
    {
      links: mappedStructures,
    },
  ];

  const handleRedirect = (event, element) => {
    event.preventDefault();
    history.replace(element.url);
  };

  const editEntity = (entity) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (entity.type === "restaurant") {
      const unit = units.find((element) => element.id === entity.id);
      if (unit) {
        if (restaurantFormRef?.current) {
          restaurantFormRef.current.openPanel("editRestaurant", {
            ...unit,
            regionId: unit.region?.id,
            structureId: unit.structure?.id,
          });
        }
      }
    } else if (entity.type === "structure") {
      const structure = structures.find((element) => element.id === entity.id);
      if (structure) {
        if (editStructueRef?.current) {
          editStructueRef.current.editEntity(structure);
        }
      }
    } else if (entity.type === "region") {
      const region = regionsDictionary.find(
        (element) => element.key === entity.id
      );
      if (region) {
        if (editRegionRef?.current) {
          editRegionRef.current.editEntity(region);
        }
      }
    }
  };

  const changeSearch = (value) => {
    setSearchFilter(value);
  };

  const handleStatusChange = (event, option) => {
    setStatusFilter(option.key);
  };

  const statusOptions = [
    { key: "Open", text: labels.get('open') },
    { key: "Closed", text: labels.get('closed') },
    { key: "All", text: labels.get('all')},
  ];

  const label = (
    <span>
      Selected {selectedItems.length} restaurant{selectedItems.length !== 1 ? "s" : ""}
    </span>
  );

  return (
    <div className="restaurant-list">
      <div className="restaurant-list__header">
        <h2>{labels.get('restaurant_list')}</h2>
        <Dropdown
          options={statusOptions}
          selectedKey={statusFilter}
          onChange={handleStatusChange}
          className="status-dropdown"
        />
        {!groups.includes("Global") &&
          !groups.includes("Regional") &&
          !groups.includes("Franchisee") && (
            <button ref={linkRef} onClick={onShowContextualMenu}>
              <FontIcon iconName="More" className="moreButton" />
            </button>
          )}
      </div>
      <Search changeSearch={changeSearch} />
      {!loadingUnits ? (
        <Nav
          groups={restaurantGroups}
          onLinkClick={handleRedirect}
          onLinkExpandClick={handleExpand}
          selectedKey={pathname}
          className="restaurant-list__collapse"
          onRenderLink={(link) => {
            var unit = null;
            if (link.type === 'restaurant') {
              unit = units.find((entity) => entity.id === link.id);
            }
            const linkClass = statusFilter === 'All' && unit != null && unit.isClosed ? 'restaurant-list__link--closed' : '';
            if (link.edit) {
              return (
                <div className="restaurant-list__edit-container">
                  <div className={`restaurant-list__link ${linkClass}`}>{link.name}</div>
                  <div className="restaurant-list__edit">
                    <button onClick={editEntity(link)} className="edit-button">
                      <FontIcon iconName="Edit" className="edit-button__icon" />
                    </button>
                  </div>
                </div>
              );
            }
            return link.name;
          }}
        />
      ) : (
        <Spinner className="spinner--small" label={`${labels.get('loading')}...`} />
      )}       
      <RestaurantForm ref={restaurantFormRef} />
      <EditStructure ref={editStructueRef} />
      <EditRegion ref={editRegionRef} />
    </div>
  );
};

export default RestaurantList;
