import React, { useEffect, useState } from "react";
import {
    DetailsList,
    DetailsListLayoutMode,
    Sticky,
    StickyPositionType,
    ScrollbarVisibility,
    ConstrainMode,
    ScrollablePane,
    TextField,
    TooltipHost,
    SelectionMode,
    FontIcon,
    ContextualMenu,
    Panel,
    PanelType,
    PrimaryButton,
    Dialog,
    DialogType,
    DialogFooter,
    DefaultButton,
} from "office-ui-fabric-react";
import useWindowSize from "../Common/useWindowSize";
import Autocomplete from "../Common/Autocomplete";
import UserAction from "./UserAction";
import {
    assignUnitToMailbox,
} from "../Services/UnitService";
import { useStateValue } from '../State/stateProvider';

import "./Restaurant.scss";

const Restaurant = ({
                        unitName,
                        unitEmployees,
                        updateEmployees,
                        sortedColumnKey,
                        isSortedDescending,
                        groups,
                        employeeTypes,
                        jobTitles,
                        filterBy,
                        filters,
                        unitId,
                        createSharedEmail,
                        userEmployees,
                        isClosed
                    }) => {
    const size = useWindowSize();

    const [{allLabels: {labels}}, dispatch] = useStateValue();
    const [openDialogMailbox, setOpenDialogMailbox] = useState(false);
    const [sortedEmployees, setSortedEmployees] = useState([]);
    const [_isSortedDescending, setIsSortedDescending] = useState(true);
    const [sortedColumn, setSortedColumn] = useState(null);


    useEffect(() => {
        const sorted = initialSort(unitEmployees);
        setSortedEmployees(sorted);
    }, [unitEmployees]);


    const initialSort = (employees) => {
        const statusOrder = {'active': 1, 'deactive': 2, 'fired': 3};

        return employees.sort((a, b) => {
            if (statusOrder[a.status] < statusOrder[b.status]) {
                return -1;
            }
            if (statusOrder[a.status] > statusOrder[b.status]) {
                return 1;
            }

            return b.level - a.level;
        });
    };


    const sortEmployees = (employees, fieldName, _isSortedDescending, additionalField) => {
        const statusOrder = {active: 1, deactive: 2, fired: 3};

        const sorted = [...employees].sort((a, b) => {
            if (fieldName === 'status') {
                if (statusOrder[a.status] < statusOrder[b.status]) {
                    return _isSortedDescending ? -1 : 1;
                }
                if (statusOrder[a.status] > statusOrder[b.status]) {
                    return _isSortedDescending ? 1 : -1;
                }
            } else {
                if (a[fieldName] < b[fieldName]) {
                    return _isSortedDescending ? -1 : 1;
                }
                if (a[fieldName] > b[fieldName]) {
                    return _isSortedDescending ? 1 : -1;
                }
            }

            return 0;
        });

        return sorted;
    };

    const onColumnClick = (column, additionalField) => {
        let newIsSortedDescending = isSortedDescending;

        if (sortedColumn === column.fieldName) {
            newIsSortedDescending = !newIsSortedDescending;
        } else {
            newIsSortedDescending = false;
        }

        const sorted = sortEmployees(unitEmployees, column.fieldName, newIsSortedDescending, additionalField);

        setIsSortedDescending(newIsSortedDescending);
        setSortedColumn(column.fieldName);
        setSortedEmployees(sorted);
    };


    const columns = [
        {
            key: "firstname",
            name: labels.get('name'),
            fieldName: "firstname",
            minWidth: 250,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            sortAscendingAriaLabel: labels.get('sorted_az'),
            sortDescendingAriaLabel: labels.get('sorted_za'),
            onColumnClick: (e, column) => onColumnClick(column, "surname"),
            data: "string",
            onRender: (item) => {
                return (
                    <div className="list__username">
                        <div className={ `avatar ${ item.status }` }></div>
                        <div className="content">
                            <div className="content__name">
                                { item.firstname } { item.surname }
                            </div>
                            <div className="content__mail">
                                <div className="mail">{ item.emailWork }</div>
                                <div className="tags__wrapper">
                                    { item.isUnitAdmin ? (
                                        <div className="badge">admin</div>
                                    ) : null }
                                    { item.mailboxStatus === 'created' ? (
                                        <div className="badge mailbox-badge">mailbox</div>
                                    ) : null }
                                    { item.isInSyncWithCompanyTenant ? (
                                        <div className="badge companyTenant-badge">amrest.eu</div>
                                    ) : null }
                                </div>
                            </div>

                        </div>
                        { groups.includes("Unit") ||
                        groups.includes("Regional") ||
                        groups.includes("Franchisee") ||
                        groups.includes("Global") ? (
                            <div className="list__action" data-selection-disabled={ true }>
                                <UserAction item={ item }
                                userEmployees={userEmployees}/>
                            </div>
                        ) : null }
                    </div>
                );
            },
            isPadded: true,
            isSorted: sortedColumnKey === "firstname",
            isSortedDescending,
        },
        {
            key: "supervisorName",
            name: labels.get('supervisor'),
            fieldName: "supervisorName",
            minWidth: 70,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            sortAscendingAriaLabel: labels.get('sorted_az'),
            sortDescendingAriaLabel: labels.get('sorted_za'),
            onColumnClick: (e, column) => onColumnClick(column),
            data: "string",
            onRender: (item) => {
                return <div className="list__cell">{ item.supervisorName }</div>;
            },
            isPadded: true,
            isSorted: sortedColumnKey === "supervisorName",
            isSortedDescending,
        },
        {
            key: "level",
            name: labels.get('level'),
            fieldName: "level",
            minWidth: 70,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            sortAscendingAriaLabel: labels.get('sorted_az'),
            sortDescendingAriaLabel: labels.get('sorted_za'),
            onColumnClick: (e, column) => onColumnClick(column),
            data: "string",
            onRender: (item) => {
                return <div className="list__cell">{ item.level }</div>;
            },
            isPadded: true,
            isSorted: sortedColumnKey === "level",
            isSortedDescending,
        },
        {
            key: "status",
            name: labels.get('status'),
            fieldName: "status",
            minWidth: 70,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            sortAscendingAriaLabel: labels.get('sorted_az'),
            sortDescendingAriaLabel: labels.get('sorted_za'),
            data: "string",
            onColumnClick: (e, column) => onColumnClick(column),
            onRender: (item) => {
                let statusText;
                switch (item.status) {
                    case 'fired':
                        statusText = 'terminated';
                        break;
                    case 'deactive':
                        statusText = 'suspended';
                        break;
                    case 'active':
                        statusText = 'active';
                        break;
                    default:
                        statusText = 'unknown';
                        break;
                }
                return <div className="list__cell"> { statusText } </div>;
            },
            isPadded: true,
            isSorted: sortedColumnKey === "status",
            isSortedDescending,
        },
        {
            key: "isInSyncWithAD",
            name: labels.get('sync_with_ad'),
            fieldName: "isInSyncWithAD",
            minWidth: 80,
            maxWidth: 90,
            isRowHeader: true,
            isResizable: true,
            sortAscendingAriaLabel: labels.get('sorted_az'),
            sortDescendingAriaLabel: labels.get('sorted_za'),
            data: "string",
            onColumnClick: (e, column) => onColumnClick(column),
            onRender: (item) => {
                if (item.syncFailedMessage) {
                    return (
                        <TooltipHost
                            id={ `syncFailedTooltip-${ item.id }` }
                            content={ item.syncFailedMessage }
                        >
                            <div className="list__cell" aria-describedby={ `syncFailedTooltip-${ item.id }` }>
                                { item.isInSyncWithAD ? labels.get('yes') : `${ labels.get('processing') }...` } { `- ${ labels.get('error') }!` }
                            </div>
                        </TooltipHost>
                    );
                }

                return (
                    <div className="list__cell">
                        { item.isInSyncWithAD ? labels.get('yes') : `${ labels.get('processing') }...` }
                    </div>
                );
            },
            isPadded: true,
            isSorted: sortedColumnKey === "isInSyncWithAD",
            isSortedDescending,
        },
        {
            key: "jobTitleName",
            name: labels.get('job_title'),
            fieldName: "jobTitleName",
            minWidth: 90,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            sortAscendingAriaLabel: labels.get('sorted_az'),
            sortDescendingAriaLabel: labels.get('sorted_za'),
            data: "string",
            onColumnClick: (e, column) => onColumnClick(column),
            onRender: (item) => {
                return <div className="list__cell">{ item.jobTitleName }</div>;
            },
            isPadded: true,
            isSorted: sortedColumnKey === "jobTitle",
            isSortedDescending,
        },
        {
            key: "employeeTypeName",
            name: labels.get('employee_type'),
            fieldName: "employeeTypeName",
            minWidth: 90,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            sortAscendingAriaLabel: labels.get('sorted_az'),
            sortDescendingAriaLabel: labels.get('sorted_za'),
            data: "string",
            onColumnClick: (e, column) => onColumnClick(column),
            onRender: (item) => {
                return <div className="list__cell">{ item.employeeTypeName }</div>;
            },
            isPadded: true,
            isSorted: sortedColumnKey === "employeeType",
            isSortedDescending,
        },
        {
            key: "principalId",
            name: labels.get('upn'),
            fieldName: "principalId",
            minWidth: 120,
            maxWidth: 140,
            isRowHeader: true,
            isResizable: true,
            sortAscendingAriaLabel: labels.get('sorted_az'),
            sortDescendingAriaLabel: labels.get('sorted_za'),
            data: "string",
            onColumnClick: (e, column) => onColumnClick(column),
            onRender: (item) => {
                return (
                    <div className="list__cell list__cell--no-text-transform">
                        { item.principalId }
                    </div>
                );
            },
            isPadded: true,
            isSorted: sortedColumnKey === "principalId",
            isSortedDescending,
        },
    ];

    const getKey = (item) => {
        return item.id;
    };

    // const [selection] = useState(
    //   new Selection({
    //     getKey: getKey,
    //     onSelectionChanged: () => {
    //       setSelectedItems(selection.getSelection());
    //     },
    //     canSelectItem: (item) => item.status === "active",
    //   })
    // );

    const handleAssignUnitToMailbox = async () => {
        const success = await assignUnitToMailbox(unitId);

        if (success) {
            dispatch({
                type: "changeRefreshData",
            });
        }
    }

    return (
        <div className="restaurant">
            <h2 className="restaurant__heading">{ unitName } - { labels.get('employees_list') }  { isClosed ? (<div className="closed-badge">{labels.get('closed')}</div>) : null}</h2>
            { !createSharedEmail && !groups.includes('Unit') ? (<div className="restaurant__button">
                <PrimaryButton
                    onClick={ () => setOpenDialogMailbox(true) }>{ labels.get('assign') } { unitName } { labels.get('to_shared_mailbox') }</PrimaryButton>
            </div>) : null }

            <div className="restaurant__filters">
                <TextField
                    label={ `${ labels.get('filter_by') } ${ labels.get('name') }:` }
                    value={ filters.name }
                    onChange={ (_, text) => filterBy(text, "name") }
                />
                <TextField
                    label={ `${ labels.get('filter_by') } ${ labels.get('email_work') }:` }
                    value={ filters.emailWork }
                    onChange={ (_, text) => filterBy(text, "emailWork") }
                />
                <TextField
                    label={ `${ labels.get('filter_by') } ${ labels.get('level') }:` }
                    value={ filters.level }
                    onChange={ (_, text) => filterBy(text, "level") }
                />
                <Autocomplete
                    label={ `${ labels.get('filter_by') } ${ labels.get('status') }:` }
                    options={ [
                        {key: '', text: ''},
                        {key: 'active', text: 'Active'},
                        {key: 'deactive', text: 'Suspended'},
                        {key: 'fired', text: 'Terminated'}
                    ] }
                    value={ filters.status }
                    onChange={ (e, value) => filterBy(value ? value.key : '', "status") }
                    small
                />
                <Autocomplete
                    label={ `${ labels.get('filter_by') } ${ labels.get('job_title') }:` }
                    options={ [{key: "", text: ""}, ...jobTitles] }
                    value={ filters.jobTitle }
                    onChange={ (e, value) => filterBy(value.key, "jobTitle") }
                    small
                />
                <Autocomplete
                    label={ `${ labels.get('filter_by') } ${ labels.get('employee_type') }:` }
                    options={ [{key: "", text: ""}, ...employeeTypes] }
                    value={ filters.employeeType }
                    onChange={ (e, value) => filterBy(value.key, "employeeType") }
                    small
                />
                <TextField
                    label={ `${ labels.get('filter_by') } ${ labels.get('upn') }:` }
                    value={ filters.principalId }
                    onChange={ (_, text) => filterBy(text, "principalId") }
                />
            </div>
            <div className="restaurant__list">
                <ScrollablePane scrollbarVisibility={ ScrollbarVisibility.auto }>
                    <DetailsList
                        items={ sortedEmployees }
                        compact={ false }
                        columns={ columns }
                        // selectionMode={SelectionMode.multiple}
                        selectionMode={ SelectionMode.none }
                        setKey="id"
                        getKey={ getKey }
                        constrainMode={ ConstrainMode.unconstrained }
                        layoutMode={
                            size.width > 1024
                                ? DetailsListLayoutMode.justified
                                : DetailsListLayoutMode.fixedColumns
                        }
                        onRenderRow={ (props, defaultRender) =>
                            props.item?.status !== "active"
                                ? defaultRender({...props, className: "list__row--disabled"})
                                : defaultRender(props)
                        }
                        isHeaderVisible={ true }
                        // selection={selection}
                        selectionPreservedOnEmptyClick={ true }
                        enterModalSelectionOnTouch={ true }
                        ariaLabelForSelectionColumn={ labels.get('toggle_selection') }
                        ariaLabelForSelectAllCheckbox={ labels.get('toggle_selection_for_all_items') }
                        checkButtonAriaLabel="Row checkbox"
                        onRenderDetailsHeader={ (headerProps, defaultRender) => {
                            return (
                                <Sticky
                                    stickyPosition={ StickyPositionType.Header }
                                    isScrollSynced={ true }
                                    stickyBackgroundColor="transparent"
                                >
                                    <div>{ defaultRender(headerProps) }</div>
                                </Sticky>
                            );
                        } }
                    />
                    { !unitEmployees || unitEmployees.length === 0 ? (
                        <div style={ {textAlign: "center"} }>
                            { labels.get('this_unit_has_no_employees') }
                        </div>
                    ) : null }
                    {/* <MarqueeSelection selection={selection}>
          </MarqueeSelection> */ }
                    <Dialog
                        hidden={ !openDialogMailbox }
                        onDismiss={ () => setOpenDialogMailbox(false) }
                        dialogContentProps={ {
                            type: DialogType.normal,
                            title: `${ labels.get('are_you_sure_you_want_to') } ${ labels.get('assign') } ${ unitName } ${ labels.get('to_shared_mailbox') }?`,
                        } }
                    >
                        <DialogFooter>
                            <PrimaryButton onClick={ () => handleAssignUnitToMailbox() } text={ labels.get('assign') }/>
                            <DefaultButton onClick={ () => setOpenDialogMailbox(false) } text={ labels.get('cancel') }/>
                        </DialogFooter>
                    </Dialog>
                </ScrollablePane>
            </div>
        </div>
    );
};

export default Restaurant;
