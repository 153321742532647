import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
} from "react";
import {
  Panel,
  PanelType,
  PrimaryButton,
  Separator,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
} from "office-ui-fabric-react";
import { toast } from "react-toastify";
import { useStateValue } from "../../../State/stateProvider";
import {
  putUnit,
  getStructuresUnits,
  getAllUnits,
  deleteUnit,
  closeUnit
} from "../../../Services/UnitService";
import { getAllRegions } from "../../../Services/RegionService";
import { getAllStructures } from "../../../Services/StructureService";
import Form from "../../../Common/Form";
import Autocomplete from '../../../Common/Autocomplete';
import { getRegionEmployees } from "../../../Services/EmployeeService";

// eslint-disable-next-line no-useless-escape
const emailTester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

// eslint-disable-next-line no-useless-escape
const phoneTester = /^[\+]?[(]?[0-9]{2,3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}[-\s\.]?([0-9]{3,6})?/;

const SendEditRequest = forwardRef((props, ref) => {
  const [{ management, allLabels: { labels } }, dispatch] = useStateValue();
  const {
    regionsDictionary,
    units,
    unitsDictionary,
    structuresDictionary,
    costCenters,
    companies,
    brands,
    countries,
    restaurantConcepts,
    restaurantAssetTypes,
    franchiseBusinessCoaches
  } = management;

  const [type, setType] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [footerText, setFooterText] = useState("");
  const [item, setItem] = useState({});
  const [regionEmployees, setRegionEmployees] = useState([]);
  
  const onDismiss = () => setIsOpen(false);

  const fetchRegionEmployees = async (regionId) => {
    const results = await getRegionEmployees(regionId);
    const employees = results.data.map(
      employee => ({
        id: employee.id,
        key: employee.id,
        name: `${employee.firstname} ${employee.surname}`
      })
    );

    return employees;
  }

  useEffect(() => {
    const fetchEmployees = async (regionId) => {
      const employees = await fetchRegionEmployees(regionId);
      setRegionEmployees(employees);
    }
    item.regionId && fetchEmployees(item.regionId);
  }, [item.regionId]);

  const changeItem = (value, field) => {
    const newItem = { ...item, [field]: value };

    if (field === "countryId") {
      if (value?.id) {
        newItem.countryId = value.id;
        newItem.country = value;
      } else {
        newItem.countryId = null;
        newItem.country = null;
      }
    } else if (field === "brandId") {
      if (value?.id) {
        newItem.brandId = value.id;
        newItem.brand = value;
      } else {
        newItem.brandId = null;
        newItem.brand = null;
      }
    } else if (field === "restaurantConceptId") {
      if (value?.id) {
        newItem.restaurantConceptId = value.id;
        newItem.restaurantConcept = value;
      } else {
        newItem.restaurantConceptId = null;
        newItem.restaurantConcept = null;
      }
    } else if (field === "restaurantAssetTypeId") {
      if (value?.id) {
        newItem.restaurantAssetTypeId = value.id;
        newItem.restaurantAssetType = value;
      } else {
        newItem.restaurantAssetTypeId = null;
        newItem.restaurantAssetType = null;
      }
    } else if (field === "costCenterId") {
      if (value?.id) {
        newItem.costCenterId = value.id;
        newItem.costCenter = value;
      } else {
        newItem.costCenterId = null;
        newItem.costCenter = null;
      }
    } else if (field === "companyId") {
      if (value?.id) {
        newItem.companyId = value.id;
        newItem.company = value;
      } else {
        newItem.companyId = null;
        newItem.company = null;
      }
    } else if (field === "unitManagerId") {
      if (value?.id) {
        newItem.unitManagerId = value.id;
        newItem.unitManager = value;
      } else {
        newItem.unitManagerId = null;
        newItem.unitManager = null;
      }
    } else if (field === "structureId") {
      if (value?.id) {
        if (newItem.structureId !== value.id) {
          newItem.regionId = null;
          newItem.region = null;
        }

        newItem.structureId = value.id;
        newItem.structure = value;
      } else {
        newItem.structureId = null;
        newItem.structure = null;
        newItem.regionId = null;
        newItem.region = null;
      }
    } else if (field === "regionId") {
      if (value?.id) {
        newItem.regionId = value.id;
        newItem.region = value;
      } else {
        newItem.regionId = null;
        newItem.region = null;
      }
    } else if (field === "sharedEmailAddress") {
      if (value) {
        newItem.createSharedEmail = true;
      } else {
        newItem.createSharedEmail = false;
      }
    } else if (field === "franchiseBusinessCoachId") {
      if (value?.id) {
        newItem.franchiseBusinessCoachId = value.id;
        newItem.franchiseBusinessCoach = value;
      } else {
        newItem.franchiseBusinessCoachId = null;
        newItem.franchiseBusinessCoach = null;
      }
    }

    setItem(newItem);
  };

  useImperativeHandle(ref, () => ({
    async openPanel(type, entity) {
        if (entity) {

        if (entity.country) {
          entity.countryId = entity.country.id;
        }
        if (entity.brand) {
          entity.brandId = entity.brand.id;
        }
        if (entity.costCenter) {
          entity.costCenterId = entity.costCenter.id;
        }
        if (entity.company) {
          entity.companyId = entity.company.id;
        }
        if (entity.structure) {
          entity.structureId = entity.structure.id;
        }
        if (entity.region) {
          entity.regionId = entity.region.id;
        }
        if(entity.franchiseBusinessCoach) {
          entity.franchiseBusinessCoachId = entity.franchiseBusinessCoach.id;
        }
        if(entity.unitManager) {
          entity.unitManagerId = entity.unitManager.id;
        }
        if(entity.restaurantAssetType) {
          entity.restaurantAssetTypeId = entity.restaurantAssetType.id;
        }
        if(entity.restaurantConcept) {
          entity.restaurantConceptId = entity.restaurantConcept.id;
        }

        if(entity.region) {
          const employees = await fetchRegionEmployees(entity.region.id);
          setRegionEmployees(employees);
        }

        setItem(entity);
      } else {
        setItem({});
      }
      setType(type);
      setIsOpen(true);
    },
  }));

  useEffect(() => {
    if (type === "addRequest") {
      setHeaderText('send_request_to_add_restaurant');
      setFooterText('send_request');
    }

    if (type === "editRequest") {
      setHeaderText('send_request_to_edit_restaurant');
      setFooterText('send_request');
    }

    if (type === "editRestaurant") {
      setHeaderText('edit_restaurant');
      setFooterText('save_changes');
    }
  }, [type]);

  const fields = [
    {
      type: "column",
      key: "1",
      separator: true,
      fields: [
        {
          type: "dropdown",
          label: labels.get('structure'),
          name: "structureId",
          options: structuresDictionary,
          required: true,
          disabled: type !== "addRequest",
        },
        {
          type: "dropdown",
          label: labels.get('region'),
          name: "regionId",
          options: regionsDictionary.filter(
            (region) => region.structureId === item.structureId
          ),
          required: true,
        },
        {
          type: "text",
          label: labels.get('restaurant_name'),
          name: "unitName",
          required: true,
        },
        {
          type: "dropdown",
          label: labels.get('restaurant_manager'),
          name: "unitManagerId",
          options: regionEmployees,
          required: false,
        },
        {
          type: "dropdown",
          label: labels.get('franchise_business_coach'),
          name: "franchiseBusinessCoachId",
          options: franchiseBusinessCoaches,
          required: true,
        },
      ],
    },
    {
      type: "column",
      key: "2",
      separator: true,
      fields: [
        {
          type: "dropdown",
          label: labels.get('cost_center'),
          name: "costCenterId",
          options: costCenters.filter(costCenter => costCenter.mailboxStatus === "created"),
          required: true,
          showCode: true,
          disabled: type === "editRestaurant" || type === "editRequest",
        },
        {
          type: "dropdown",
          label: labels.get('company'),
          name: "companyId",
          options: companies,
          required: true,
          disabled: type === "editRestaurant" || type === "editRequest",
        },
        {
          type: "dropdown",
          label: labels.get('brand'),
          name: "brandId",
          options: brands,
          required: true,
        },
        {
          type: "dropdown",
          label: labels.get('concept'),
          name: "restaurantConceptId",
          options: restaurantConcepts,
          required: false,
        },
        {
          type: "dropdown",
          label: labels.get('asset_type'),
          name: "restaurantAssetTypeId",
          options: restaurantAssetTypes,
          required: false,
        },
      ],
    },
    {
      type: "columns",
      key: "3",
      separator: true,
      fields: [
        {
          type: "dropdown",
          label: labels.get('country'),
          name: "countryId",
          options: countries,
          required: true,
        },
        {
          type: "text",
          label: labels.get('city'),
          name: "city",
        },
        {
          type: "text",
          label: labels.get('street'),
          name: "street",
        },
        {
          type: "text",
          label: labels.get('zip_code'),
          name: "zipCode",
        },
      ],
    },
    {
      type: "columns",
      key: "4",
      separator: true,
      fields: [
        {
          type: "text",
          label: labels.get('shared_email_address'),
          name: "sharedEmailAddress",
          disabled: true,
          fullWidth: true
        },
        {
          type: "date",
          label: labels.get('opening_date'),
          name: "openingDate",
          minDate: new Date("1970-01-01"),
          required: true,
        },
        {
          type: "date",
          label: labels.get('close_date'),
          name: "closeTime",
          minDate: new Date("1970-01-01"),
        },
        {
          type: "phone",
          label: labels.get('phone_number'),
          name: "phoneNumber",
        },
        {
          type: "phone",
          label: labels.get('franchise_phone_number'),
          name: "franchiseePhoneNumber",
        },
      ],
    },
    {
      type: "columns",
      key: "5",
      fields: [
        {
          type: "text",
          label: labels.get('comments'),
          name: "comments",
          fullWidth: true,
          multiline: true,
          length: 250,
        }
      ]
    }
  ];

  const confirmChanges = async () => {
    const flatFields = [];
    fields.forEach((entry) => {
      flatFields.push(...entry.fields);
    });

    const error = Object.entries(item).find(([key, value]) => {
      if (
        flatFields.find((entry) => entry.type === "email" && entry.name === key)
      ) {
        if (value && !emailTester.test(value)) {
          return true;
        }
      }

      if (
        flatFields.find((entry) => entry.type === "phone" && entry.name === key)
      ) {
        if (value && !phoneTester.test(value)) {
          return true;
        }
      }

      return false;
    });

    const requiredDataMissing =
      (type.includes("edit") && !item.id) ||
      !item.structureId ||
      !item.regionId ||
      !item.unitName ||
      !item.openingDate ||
      !item.costCenterId ||
      !item.companyId ||
      !item.brandId ||
      !item.countryId;

    if (!error && !requiredDataMissing) {      
      if (type === "editRestaurant") {
        const editedUnit = await putUnit(item.id, item);

        if (editedUnit?.data?.id) {
          setItem({});
          onDismiss();
          dispatch({
            type: "changeLoadingUnits",
            loading: true,
          });

          const newStructures = await getAllStructures();
          const newStructuresUnits = await getStructuresUnits();
          const newUnits = await getAllUnits();
          const newRegions = await getAllRegions();

          if (
            newStructures?.data &&
            newStructuresUnits?.data &&
            newUnits?.data &&
            newRegions?.data
          ) {
            dispatch({
              type: "changeStructuresRegionsUnits",
              newStructures: newStructures.data,
              newStructuresUnits: newStructuresUnits.data,
              newUnits: newUnits.data,
              newRegions: newRegions.data,
            });
          }

          dispatch({
            type: "changeLoadingUnits",
            loading: false,
          });
        }
      }
    } else {
      if (error) {
        toast.error(labels.get('correct_form_errors'));
      }
      if (requiredDataMissing) {
        toast.error(labels.get('fill_required_fields'));
      }
    }
  };

  const [dialog, setDialog] = useState(false);

  const openDialog = () => {
    setDialog(true);
  };

  const closeDialog = () => setDialog(false);

  const confirmDialog = useCallback(async () => {
    const response = await deleteUnit(item.id);
    setDialog(false);

    if (response) {
      toast.success(labels.get('unit_deleted'));
      setIsOpen(false);
      setItem({});
      dispatch({
        type: "changeLoadingUnits",
        loading: true,
      });

      const newStructures = await getAllStructures();
      const newStructuresUnits = await getStructuresUnits();
      const newUnits = await getAllUnits();
      const newRegions = await getAllRegions();

      if (
        newStructures?.data &&
        newStructuresUnits?.data &&
        newUnits?.data &&
        newRegions?.data
      ) {
        dispatch({
          type: "changeStructuresRegionsUnits",
          newStructures: newStructures.data,
          newStructuresUnits: newStructuresUnits.data,
          newUnits: newUnits.data,
          newRegions: newRegions.data,
        });
      }

      dispatch({
        type: "changeLoadingUnits",
        loading: false,
      });
    }
  }, [item, dispatch]);

  const [closingDialog, setClosingDialog] = useState(false);

  const openClosingDialog = () => setClosingDialog(true);

  const closeClosingDialog = () => setClosingDialog(false);

  const confirmCloseDialog = useCallback(async () => {

    console.log(item)
    console.log(item.isClosed)
    const response = await closeUnit(item.id);
    closeClosingDialog();

    if (response) {
      toast.success(labels.get('unit_closed'));
      setIsOpen(false);
      setItem({});
      dispatch({
        type: "changeLoadingUnits",
        loading: true,
      });

      const newStructures = await getAllStructures();
      const newStructuresUnits = await getStructuresUnits();
      const newUnits = await getAllUnits();
      const newRegions = await getAllRegions();

      if (
        newStructures?.data &&
        newStructuresUnits?.data &&
        newUnits?.data &&
        newRegions?.data
      ) {
        dispatch({
          type: "changeStructuresRegionsUnits",
          newStructures: newStructures.data,
          newStructuresUnits: newStructuresUnits.data,
          newUnits: newUnits.data,
          newRegions: newRegions.data,
        });
      }

      dispatch({
        type: "changeLoadingUnits",
        loading: false,
      });
    }
}, [item, dispatch]);

  const onRenderFooterContent = () => (
    <div style={{ display: "flex", gap: "12px" }}>
      <PrimaryButton style={{ width: "30%" }} onClick={confirmChanges}>{labels.get(footerText)}</PrimaryButton>

      {type === "editRestaurant" ? (
        <>
          {item.isClosed === false && (
            <DefaultButton style={{ width: "30%" }} onClick={openClosingDialog}>
              {labels.get('close_restaurant')}
            </DefaultButton>
          )}
          <DefaultButton style={{ width: "30%" }} onClick={openDialog}>{labels.get('delete_restaurant')}</DefaultButton>
          <Dialog
            hidden={!dialog}
            onDismiss={closeDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: labels.get('delete_restaurant'),
            }}
          >
            <p>{labels.get('are_you_sure_you_want_to_delete')} {item.name}?</p>
            <DialogFooter>
              <PrimaryButton onClick={confirmDialog} text={labels.get('delete')} />
              <PrimaryButton onClick={closeDialog} text={labels.get('cancel')} />
            </DialogFooter>
          </Dialog>
          <Dialog
            hidden={!closingDialog}
            onDismiss={closeClosingDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: labels.get('close_restaurant'),
            }}
          >
            <p>{labels.get('are_you_sure_you_want_to_close')} {item.name}?</p>
            <DialogFooter>
              <PrimaryButton onClick={confirmCloseDialog} text={labels.get('close')} />
              <PrimaryButton onClick={closeClosingDialog} text={labels.get('cancel')} />
            </DialogFooter>
          </Dialog>
        </>
      ) : null}
    </div>
  );

  const fillItemWithUnit = (value) => {
    const unit = units.find((element) => element.id === value.key);

    if (unit) {
      setItem((oldItem) => {
        return {
          ...oldItem,
          ...unit,
          regionId: unit.region?.id,
          structureId: unit.structure?.id,
          unitId: value.key,
        };
      });
    } else {
      setItem((oldItem) => {
        return {
          ...oldItem,
          unitId: value.key,
        };
      });
    }
  };

  return (
    <Panel
      headerText={labels.get(headerText)}
      type={PanelType.custom}
      customWidth={"28rem"}
      isLightDismiss
      isOpen={isOpen}
      onDismiss={onDismiss}
      closeButtonAriaLabel={labels.get('close')}
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
    >
      {type === "editRequest" ? (
        <div>
          <Autocomplete
            label="Unit ID"
            options={unitsDictionary}
            value={item?.unitId || ""}
            onChange={(e, value) => fillItemWithUnit(value)}
            disablePortal
            required
          />
          <Separator className="separator" />
        </div>
      ) : null}
      {regionEmployees && <Form item={item} changeItem={changeItem} fields={fields} />}
    </Panel>
  );
});

export default SendEditRequest;
