import { ApiClient as Client, handleLogout } from "./ApiService";
import { toast } from "react-toastify";

export const getAllRestaurantConcepts = async (regionId) => {
  try {
    const response = await Client.get_All_Restaurants_concepts(regionId);
    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      toast.error('Something went wrong');
    }
    return false;
  }
};

export const getAllRestaurantAssetTypes = async (regionId) => {
  try {
    const response = await Client.get_All_Restaurants_asset_types(regionId);
    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      toast.error('Something went wrong');
    }
    return false;
  }
};

export const getUnitById = async (unitId) => {
  try {
    const response = await Client.get_Unit(unitId);
    return response;
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      handleLogout();
    } else if (error?.errors) {
      error.errors.forEach((err) => {
        toast.error(err.message);
      });
    } else {
      toast.error('Something went wrong');
    }
    return false;
  }
};