import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'office-ui-fabric-react';
import { getUnitEmployees, getUserEmployeesLookup } from '../Services/EmployeeService';
import { getUnit } from '../Services/UnitService';
import Restaurant from './Restaurant';
import { useStateValue } from '../State/stateProvider';
import usePrevious from '../Common/usePrevious'

import './Restaurant.scss';

const RestaurantContainer = () => {
  const { unit: unitId } = useParams();

  const [{ auth, management, allLabels: { labels } }] = useStateValue();
  const { refreshData, employeeTypes, jobTitles } = management;
  const { groups } = auth;

  const [unitName, setUnitName] = useState(null);
  const [isClosed, setIsClosed] = useState(false);
  const [createSharedEmail, setCreateSharedEmail] = useState(false);
  const [pureUnitEmployees, setPureUnitEmployees] = useState(null);
  const [unitEmployees, setUnitEmployees] = useState(null);
  const [sortedColumnKey, setSortedColumnKey] = useState(null);
  const [sortedDescending, setSortedDescending] = useState(false);
  const [sortedAdditionalField, setSortedAdditionalField] = useState(false);
  const [userEmployees, setUserEmployees] = useState([]);

  const [filters, setFilters] = useState({});

  const filterEmployees = useCallback((employees) => {
    if (employees) {
      const newEmployees = employees.filter((employee) => {
        let valid = true;

        for (let [key, value] of Object.entries(filters)) {
          if (value) {
            if (typeof employee[key] === 'string') {
              if (employee[key].toLowerCase() !== value.toLowerCase()) {
                valid = false;
                break;
              }
            } else if (typeof employee[key] === 'number') {
              if (employee[key].toString() !== value) {
                valid = false;
                break;
              }
            } else if (employee[key] instanceof Object) {
              if (employee[key].id !== value) {
                valid = false;
                break;
              }
            }
          }
        }

        return valid;
      });

      return newEmployees;
    }

    return employees;
  }, [filters]);

  useEffect(() => {
    const updateEmployees = async () => {
      const result = await getUserEmployeesLookup();
      setUserEmployees(result.data);
    };
    updateEmployees();
    }, []);


  const updateEmployees = (columnKey, isSortedDescending, additionalField) => {
    setSortedColumnKey(columnKey);
    setSortedDescending(isSortedDescending);
    setSortedAdditionalField(additionalField);

    const sortDirection = isSortedDescending ? -1 : 1;
    let newUnitEmployees = [...unitEmployees];

    newUnitEmployees.sort((a, b) => {

      let valueA = a[columnKey] ?? '';
      let valueB = b[columnKey] ?? '';

      if (additionalField) {
        valueA = `${valueA} ${a[additionalField] ?? ''}`;
        valueB = `${valueB} ${b[additionalField] ?? ''}`;
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }

      if (valueA < valueB) {
        return -1 * sortDirection;
      }
      if (valueA > valueB) {
        return 1 * sortDirection;
      }
      return 0;
    });

    setUnitEmployees(filterEmployees(newUnitEmployees));
  };


  const fetchData = useCallback(
    async (unitId) => {
      setUnitEmployees(null);
      setPureUnitEmployees(null);
      const newEmployees = await getUnitEmployees(unitId);
      const unitData = await getUnit(unitId);
      if (newEmployees?.data) {
        newEmployees.data.map((employee) => {
          const newEmployee = employee;
          newEmployee.jobTitleName = newEmployee.jobTitle?.name;
          newEmployee.employeeTypeName = newEmployee.employeeType?.name;
          return newEmployee;
        });
        if (sortedColumnKey) {
          const sortDirection = sortedDescending ? 1 : -1;
          if (sortedAdditionalField) {
            const sortedData = newEmployees.data
              .slice()
              .sort(
                (a, b) =>
                  (`${a[sortedColumnKey] || ''} ${a[sortedAdditionalField] ||
                    ''}` <
                  `${b[sortedColumnKey] || ''} ${b[sortedAdditionalField] ||
                    ''}`
                    ? 1
                    : -1) * sortDirection
              );
            setPureUnitEmployees(sortedData);
            setUnitEmployees(filterEmployees(sortedData));
          } else {
            const sortedData = newEmployees.data
              .slice()
              .sort(
                (a, b) =>
                  ((a[sortedColumnKey] || '') < (b[sortedColumnKey] || '')
                    ? 1
                    : -1) * sortDirection
              );
            setPureUnitEmployees(sortedData);
            setUnitEmployees(filterEmployees(sortedData));
          }
        } else {
          setPureUnitEmployees(newEmployees.data)
          setUnitEmployees(filterEmployees(newEmployees.data));
        }
      } else {
        setPureUnitEmployees([]);
        setUnitEmployees([]);
      }

      if (unitData) {
        setUnitName(unitData.data?.unitName || '');
        setCreateSharedEmail(unitData.data?.createSharedEmail);
        setIsClosed(unitData.data?.isClosed);
      }
    },
    [sortedColumnKey, sortedDescending, sortedAdditionalField, filterEmployees]
  );

  const filterBy = (filterValue, column) => {
    let value = filterValue;

    setFilters((oldFilter) => ({ ...oldFilter, [column]: value }));
  };

  useEffect(() => {
    setUnitEmployees(filterEmployees(pureUnitEmployees));
  }, [filters, filterEmployees, pureUnitEmployees]);

  const previousUnit = usePrevious(unitId);
  const previousRefreshData = usePrevious(refreshData);

  useEffect(() => {
    if (unitId !== previousUnit || refreshData !== previousRefreshData) {
      fetchData(unitId);
    }
  }, [unitId, refreshData, fetchData, previousUnit, previousRefreshData]);

  return (
    <>
      {unitName !== null && unitEmployees !== null ? (
        <Restaurant
          unitName={unitName}
          unitEmployees={unitEmployees}
          updateEmployees={updateEmployees}
          sortedColumnKey={sortedColumnKey}
          isSortedDescending={sortedDescending}
          groups={groups}
          employeeTypes={employeeTypes}
          jobTitles={jobTitles}
          filterBy={filterBy}
          filters={filters}
          unitId={unitId}
          createSharedEmail={createSharedEmail}
          userEmployees={userEmployees}
          isClosed={isClosed}
        />
      ) : (
        <div className='center'>
          <Spinner className="spinner" label={`${labels.get('loading_restaurant')}...`} />
        </div>
      )}
    </>
  );
};

export default RestaurantContainer;
